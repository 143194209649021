import { defineStore } from 'pinia';
import currenciesJSON from "@/shared/assets/json/currencies.json";
import pricesJSON from "@/shared/assets/json/prices.json";
import { useLanguageStore } from "@/shared/stores/language.store";

export const useCurrencyStore = defineStore('currency', {
	state: () => ({
		prices: pricesJSON,
		currencies: currenciesJSON,
		currCountry: null
	}),
	getters: {
		langStore: () => useLanguageStore(),
		currCurrency: state => {
			let finalCurrency = null;
			if(state.currCountry) {
				for(let currency in state.currencies) {
					if(state.currencies[currency].countries.includes(state.currCountry)) {
						finalCurrency = state.currencies[currency];
						break;
					}

				}
			}
			return finalCurrency || state.currencies['eur'];
		},
		currMinInteger: state => {
			let finalMinAmount = 1;
			if(state?.currCurrency?.hasOwnProperty('min_stripe_amount')) {
				finalMinAmount = Number(state.currCurrency.min_stripe_amount || 1);
			}
			return finalMinAmount;
		},
		readableCurrencyValue: state => (value, fromDb = true, withSymbol = true, noDecimals = false) => {
			let finalValue = !isNaN(value) ? value : 0;
			if(fromDb) {
				finalValue = state.dbToActualValue(finalValue);
			}
			let numberStringOptions = {
				maximumFractionDigits: noDecimals ? 0 : state.currCurrency.decimals,
				minimumFractionDigits: noDecimals ? 0 : state.currCurrency.decimals
			}
			if(noDecimals) {
				numberStringOptions.roundingMode = "trunc";
			}
			let formattedValue = Number(finalValue || 0).toLocaleString([state.langStore.lang || "en"], numberStringOptions)
			let returnString = formattedValue;
			if(state?.currCurrency?.symbol && withSymbol) {
				returnString = state.currCurrency?.symbol_position === 'before' ? state.currCurrency.symbol +' '+ formattedValue : formattedValue +' '+ state.currCurrency.symbol;
			}
			return returnString;
		},
		dbToActualValue: state => value => {
			let multiplier = state.currCurrency?.hasOwnProperty('multiplier') ? Number(state.currCurrency.multiplier || 0) : 100;
			let decimals = state.currCurrency?.hasOwnProperty('decimals') ? Number(state.currCurrency.decimals || 0) : 2;
			let finalDbValue = !isNaN(value) ? Number(value) : 0;
			let finalValue = finalDbValue;
			if(!!multiplier && multiplier !== 1) {
				finalValue = Number(finalDbValue || 0) / multiplier;
			}
			return decimals === 0 ? Math.floor(finalValue) : Number(finalValue.toFixed(decimals));
		},
		actualToDbValue: state => value => {
			let multiplier = state.currCurrency?.hasOwnProperty('multiplier') ? Number(state.currCurrency.multiplier || 0) : 100;
			let finalValue = !isNaN(value) ? Number(value || 0) : 0;
			if(!!multiplier && multiplier !== 1) {
				finalValue = finalValue * multiplier;
			}
			return Math.round(finalValue);
		},
		countryCurrency: state => country => {
			let finalCurrency = state.currencies['eur'];
			if(!country) {
				return finalCurrency;
			}
			for(let currency in state.currencies) {
				if(state.currencies[currency].countries.includes(country)) {
					finalCurrency = state.currencies[currency];
					break;
				}

			}
			return finalCurrency;
		},
		priceFromId: state => (priceId) => {
			if(!priceId) { return null; }
			let foundPrice = state.prices?.[priceId]?.[TEST ? "test" : "live"]?.[0];
			if(!foundPrice) {
				foundPrice = state.currCurrency?.prices?.[priceId]?.[TEST ? "test" : "live"] || null;
			}
			return foundPrice;
		},
	},
	actions: {
		initCurrency(countryCode) {
			this.currCountry = countryCode;
		}
	},
});
